<template>
  <div class="flex auth-page">
    <div>
      <div class="space">
        <div>
          <router-link :to="`/${$i18n.locale}`" data-testid="back-btn">
            <base-icon class="svg" :width="20" name="back">
              <icon-back :fill="`#27AE60`" />
            </base-icon>
          </router-link>
        </div>
        <div v-if="!forgotSuccess" data-testid="forgot-begin">
          <form :disabled="loading" class="mb-5" @submit.prevent="submit()">
            <div class="logo flex justify-center mb-4">
              <div class="w-16 h-16 mr-2"><logo-workkami /></div>
              <div class="logo-text">WORKKAMI</div>
            </div>
            <div class="mt-4 text-2xl text-center">
              {{ $t('login.forgotPass.name') }}
            </div>
            <div class="mt-2 text-sm text-gray-500 text-center">
              {{ $t('login.forgotPass.title1') }}
              <br />{{ $t('login.forgotPass.title2') }}
            </div>
            <div v-if="error" class="mt-2 mb-2 color-error text-center">
              {{ error }}
            </div>
            <div class="mt-6">
              <base-input-text
                v-model="email"
                :disabled="loading"
                type="email"
                :placeholder="$t('login.email')"
                name="email"
                :class="{ error: error }"
                data-testid="email-input"
              />
            </div>
            <div class="mt-8 mb-4">
              <base-button
                full
                :color="isCanSubmit ? 'brand' : 'disabled'"
                type="submit"
                data-testid="send-btn"
              >
                {{ $t('login.forgotPass.send') }}
              </base-button>
            </div>
          </form>
        </div>
        <div v-else data-testid="forgot-success">
          <div class="logo-sent-mail">
            <logo-sent-mail />
          </div>
          <div class="mt-4 text-2xl text-center">
            {{ $t('login.forgotPass.check.name') }}
          </div>
          <div class="mt-2 mb-16 text-sm text-gray-500 text-center">
            {{ $t('login.forgotPass.check.title1') }}
            <br />{{ $t('login.forgotPass.check.title2') }}
          </div>
        </div>
      </div>
    </div>
    <div class="background-left">
      <background-auth-left />
    </div>
    <div class="background-right">
      <background-auth-right />
    </div>
  </div>
</template>

<script>
import BackgroundAuthLeft from '../components/icons/BackgroundAuthLeft';
import BackgroundAuthRight from '../components/icons/BackgroundAuthRight';
import IconBack from '@/components/icons/IconBack';
import LogoWorkkami from '../components/icons/LogoWorkkami';
import LogoSentMail from '../components/icons/LogoSentMail';
import LoadingMixin from '@/mixins/LoadingMixin';

export default {
  components: {
    BackgroundAuthLeft,
    BackgroundAuthRight,
    IconBack,
    LogoWorkkami,
    LogoSentMail
  },
  mixins: [LoadingMixin],
  name: 'Forgot',
  watch: {
    email: function(email) {
      if (email.length) {
        this.isCanSubmit = true;
      } else {
        this.isCanSubmit = false;
      }
    }
  },
  data() {
    return {
      email: '',
      isCanSubmit: false,
      error: '',
      forgotSuccess: false
    };
  },
  methods: {
    async submit() {
      if (!this.isCanSubmit) return;

      this.error = '';
      this.onLoading();
      try {
        await this.$store.dispatch('auth/forgot', {
          email: this.email
        });
        this.forgotSuccess = true;
        localStorage.removeItem('user');
      } catch (error) {
        this.error = error?.response?.data?.message;
      }
      this.offLoading();
    }
  }
};
</script>

<style scoped></style>
